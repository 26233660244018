@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.App {
    background-color: #282c34;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(16px + 3vmin);
    color: white;
}

.App-header h1 {
    margin: 0;
}

.App-header p {
    margin-top: 1.5em;
    font-size: 0.6em;
    text-transform: uppercase;
}

.App-header .links {
    display: flex;
    margin-top: 2vw;
    height: 4vw;
}

.App-header .links a {
    margin: 0 2vw 0 2vw;
    height: 100%;
    width: 100%;
}

.App-header .links a img {
    height: 100%;
    width: 100%;
}
